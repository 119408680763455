import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import storage from "redux-persist/lib/storage";
// import { persistReducer, persistStore } from "redux-persist";
// import storageSession from "redux-persist/lib/storage/session";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  dashboardLayOutReducer,
  dashboardKpisListReducer,
  dashboardKpisDataReducer,
} from "./reducers/dashboardReducers";

import { userLoginReducer } from "./reducers/userReducers";

import { permissionListReducer } from "./reducers/permissionsReducers";

import {
  rolesListReducer,
  rolesPermissionsDataListReducer,
} from "./reducers/rolesReducers";

import { allFAQDataReducer } from "./reducers/faqReducers";

import { globalSnackbarReducer } from "./reducers/globalSnackbarReducers";

import { adminFAQReducer } from "./reducers/adminFAQReducers";
import { userCRMEventsReducer } from "./reducers/adminUserEventsReducers";

import { adminDocumentationReducer } from "./reducers/adminDocumentationReducer";

import { adminManagementReducer } from "./reducers/adminManagementReduce";
import { manageDailyTipsReducer } from "./reducers/manageDailyTipsReducer";
import { userReducer2 } from './reducers/userReducer2';
import { deviceManagementReducer } from './reducers/deviceManagementReducer';
import { forgotPasswordReducer } from './reducers/forgotPasswordReducer';
import {dashboardPreviewLayOutReducer, dashboardPreviewKpisDataReducer} from "./reducers/adminDashboardPreviewReducers";
import { notificationReducer } from './reducers/notificationReducer';
import { auditLogReducer } from './reducers/auditLogReducer';
import { userAndDevicesReducer } from './reducers/userAndDevicesReducer';
import { reactionReducer } from './reducers/reactionReducer';
import { appliedLocalFilter } from "./reducers/appliedLocalFilterReducer";
// const userPersistConfig = {
//   key: "user",
//   storage: storageSession,
// };

const reducer = combineReducers({
  userLogin: userLoginReducer,
  // userLogin: persistReducer(userPersistConfig, userLoginReducer),
  globalsnackbarDetails: globalSnackbarReducer,
  myDashboardLayout: dashboardLayOutReducer,
  myDashboardAllKpisList: dashboardKpisListReducer,
  myDashboardKpisData: dashboardKpisDataReducer,
  myAllFaqData: allFAQDataReducer,
  permissionListdetails: permissionListReducer,
  rolesdetails: rolesListReducer,
  rolesPermissioDetails: rolesPermissionsDataListReducer,
  adminFAQReducer,
  adminDocumentationReducer,
  userCRMEventsData: userCRMEventsReducer,

  adminManagementReducer,

  manageDailyTipsReducer,

  userReducer2,

  deviceManagementReducer,

  forgotPasswordReducer,

  adminPreviewDashboardLayout: dashboardPreviewLayOutReducer,
  admindashboardPreviewKpisData : dashboardPreviewKpisDataReducer,
  
  notificationReducer,

  auditLogReducer,

  userAndDevicesReducer,

  reactionReducer,

  appliedLocalFilter
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// const persistor = persistStore(store);

// export { persistor };
export default store;
