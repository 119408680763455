import * as React from 'react';
import {Typography,Box,Button,TextField,Radio,RadioGroup,FormControlLabel,FormControl,FormLabel} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator'
import { sumitUserfeedbackData } from '../../../actions/userFeedbackActions'
import { showGlobalSnackbar } from "../../../actions/globalSnackbarActions";
import AccessControl from "../../../AccessControl";
import NoRenderView from '../../../components/noRenderView';


export default function UserFeedback() {
    
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const [FeedbackType, setFeedbackType] = React.useState('');
    const [userName, setuserName] = React.useState(userInfo.user_email);
    const [emailId, setemailId] = React.useState(userInfo.user_email);
    const [contactNumber, setcontactNumber] = React.useState('');
    const [comments, setcomments] = React.useState('');

    const [FeedbackTypeErrorText, setFeedbackTypeErrorText] = React.useState('');
    const [userNameErrorText, setuserNameErrorText] = React.useState('');
    const [emailIdErrorText, setemailIdErrorText] = React.useState('');
    const [contactNumberErrorText, setcontactNumberErrorText] = React.useState('');
    const [commentsErrorText, setcommentsErrorText] = React.useState('');

    const updateFeedbackType = (event) => {
        setFeedbackType(event.target.value);
    };


    const validateEmail = (emailId) => {
  
        if (validator.isEmail(emailId)) {
            setemailIdErrorText('')
            return true
        } else {
            setemailIdErrorText('Please Enter Valid Email!')
        return false
        }
    }

    const validatephoneNumber = (phoneNumber) => {
  
        if (validator.isNumeric(phoneNumber)) {
            setcontactNumberErrorText('')
            return true
        } else {
            setcontactNumberErrorText('Please Enter Valid Contact Number!')
        return false
        }
    }

    const FeedbackSubmit = () => {
        if(FeedbackType!=='' && userName!=='' && emailId !=='' /*&& contactNumber !==''*/ && comments !==''  ){
            var emailstat = validateEmail(emailId)
            // var phonestat = validatephoneNumber(contactNumber)
            if( emailstat === true /*&& phonestat === true*/){
                setFeedbackType('');
                // setuserName('');
                // setemailId('');
                // setcontactNumber('');
                setcomments('');
                setFeedbackTypeErrorText('');
                setuserNameErrorText('');
                setemailIdErrorText('');
                // setcontactNumberErrorText('');
                setcommentsErrorText('');
                dispatch(sumitUserfeedbackData(
                {
                    "creator_name" : userName,
                    // "creator_number" : contactNumber,
                    "creator_email" : emailId,
                    "creator_issue" : comments,
                    "ticket_type" : FeedbackType
                }
            ))
            }
        }
        else{
            if(!FeedbackType) {
                setFeedbackTypeErrorText("Please Provide Category");
            }
            if(!userName) {
                setuserNameErrorText("Please Provide Username");
            }
            if(!emailId) {
                setemailIdErrorText("Please Provide Email Address");
            }
            // if(!contactNumber) {
            //     setcontactNumberErrorText("Please Provide Contact Details");
            // }
            if(!comments) {
                setcommentsErrorText("Please Provide Comments");
            }
            
            dispatch(showGlobalSnackbar("Fill Up all Deatils...", "warning"))
        }
        
    };


    return (
      <div
        style={{
              //    display: "flex",
              padding: "0em 2em 2em 2em",
              overflowX: "auto",
              //    width: "100%",
              //    direction: "rtl",
              //    background: "#ef394e",
              //    boxSizing: "border-box" // <--- this line
              }}
        >
        <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'Left' , padding: "2em 0em 0em 0em",}}
        >
            <Box
                style={{display: 'flex', width:'80%' ,flexDirection: 'column', alignItems: 'Left' ,}}
            >
                <Typography component="div" variant="h2" fontWeight='bold'>
                    Feedback & Support
                </Typography>

                <Typography variant="subtitle1" color="text.secondary" component="div">
                    Please submit your query or feedback. We will get back to you.
                </Typography>

                <AccessControl 
                    allowedPermissions={["createFeedbackAndSupport"]} 
                    renderNoAccess={()=>(<NoRenderView noRenderMessage={'You Do not have permission to Create query or feedback'}/>)}
                >
                    <Box
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'left',justifyContent:"space-between",padding: "2em 0em 0em 0em"}}
                    >
                        <FormControl  error={FeedbackTypeErrorText===''? false:true}>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                                <Typography component="div" variant="h4" fontWeight='bold'>
                                    Select Category
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={FeedbackType}
                                onChange={updateFeedbackType}
                            >
                                <FormControlLabel  value="feedback" control={<Radio color="success"/>} label="Feedback" />
                                <FormControlLabel  value="support" control={<Radio color="success"/>} label="Support" />
                                {/* <FormHelperText>{FeedbackTypeErrorText}</FormHelperText> */}
                            </RadioGroup>
                        </FormControl>
                        
                    </Box>

                    <Box
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'left',justifyContent:"space-between",padding: "1em 0em 0em 0em"}}
                    >
                        <Box
                            sx={{
                                width: '95%',
                                maxWidth: '100%',
                                margin:"0em 0em 1em 1em"
                            }}
                        >
                            <TextField id="outlined-basic-Username" label="Username"
                                variant="outlined" fullWidth 
                                error={userNameErrorText===''? false:true}
                                helperText={userNameErrorText}
                                value={userName}
                                onChange={e => setuserName(e.target.value)}
                                disabled={true}
                            />
                        </Box>
                        {/* <Box
                            sx={{
                                width: '95%',
                                maxWidth: '100%',
                                margin:"0em 0em 1em 1em"
                            }}
                        >
                            <TextField id="outlined-basic-mobile" 
                                label="Contact Number" variant="outlined" fullWidth
                                error={contactNumberErrorText===''? false:true}
                                helperText={contactNumberErrorText}
                                value={contactNumber}
                                onChange={e => setcontactNumber(e.target.value)}
                            />
                        </Box> */}
                        <Box
                            sx={{
                                width: '95%',
                                maxWidth: '100%',
                                margin:"0em 0em 1em 1em"
                            }}
                        >
                            <TextField id="outlined-basic-email" label="Email" 
                                variant="outlined" fullWidth 
                                error={emailIdErrorText===''? false:true}
                                helperText={emailIdErrorText}
                                value={emailId}
                                onChange={e => setemailId(e.target.value)}
                                disabled={true}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '95%',
                                maxWidth: '100%',
                                margin:"0em 0em 1em 1em"
                            }}
                        >
                            <TextField id="outlined-basic-query" label="Comments" 
                                variant="outlined" fullWidth multiline rows={3}
                                error={commentsErrorText===''? false:true}
                                helperText={commentsErrorText}
                                value={comments}
                                onChange={e => setcomments(e.target.value)}    
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                p:"0em 7em 1em 5em",
                                alignContent:'center'
                            }}
                        >
                            <Button  
                                variant="contained"
                                size="large"
                                fullWidth
                                // bcolor="#20b2aa"
                                color='success'
                                onClick={FeedbackSubmit}
                            >
                                <Typography variant='title' fontSize={16} fontWeight="bold" >
                                    Submit
                                </Typography>
                            </Button>

                        </Box>

                    </Box>
                </AccessControl>
                
            </Box>

            <Box
                style={{display: 'flex', width:'20%', flexDirection: 'column', alignItems: 'Left' , }}
            >
                <Typography component="div" variant="h4" fontWeight='bold'>
                    Contact Us
                </Typography>
                <Box > 
                    <Typography variant='h5' fontWeight={"bold"}>
                         Name
                    </Typography>
                    <Typography variant='h6'>
                        Josie Rudderham, Energize Program Navigator
                    </Typography>
                </Box>
                <Typography component="div" variant="h5" fontWeight='bold'>
                    Email
                </Typography>

                <Typography component="div" variant="subtitle1">
                    track@energizebridgewater.ca
                </Typography>

                <Typography component="div" variant="h5" fontWeight='bold'>
                    Phone
                </Typography>

                <Typography component="div" variant="subtitle1" >
                    (902) 530-3621
                </Typography>
                <Typography component="div" variant="h5" fontWeight='bold'>
                    Address
                </Typography>

                <Typography component="div" variant="subtitle1" >
                    60 Pleasant St, Bridgewater, NS B4V 3X9
                </Typography>

                
                
            </Box>
        </Box>
               
      </div>
    )

}