import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Box,Typography,Stack,SpeedDial,SpeedDialAction, Skeleton, useMediaQuery} from '@mui/material';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { Link } from "react-router-dom";
// import * as jose from 'jose'
import { showGlobalSnackbar } from '../../actions/globalSnackbarActions';
import AuthContext from '../../Auth/authProvider';

const actions = [
  { icon: <QuestionAnswerOutlinedIcon fontSize="medium" />, name: 'Learn More', path:'/documentation' },
  { icon: <ReportGmailerrorredOutlinedIcon fontSize="medium" />, name: 'Feedback & Support', path:'/report-feedback' },
  { icon: <ContactSupportOutlinedIcon fontSize="medium" />, name: 'FAQs', path:'/faq' },
];

export default function ChatBotButton() {
  const dispatch=useDispatch()
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated,userInfo } = userLogin;

  const contextData=React.useContext(AuthContext)
  const {user} = contextData
  const _912px = useMediaQuery("(max-width:912px)")
  const _425px = useMediaQuery("(max-width:425px)")
  // const [securedJWT,setsecuredJWT]=React.useState("")
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openChatSection, setopenChatSection] = React.useState(false);
  const handleChatSectionOpen = async () => {
    setopenChatSection(true)
    // if (isAuthenticated){
    //   // const securedJWT = new jose.UnsecuredJWT({ email: userInfo.user_email}) //set actual data here
    //   // .setIssuedAt()
    //   // .setIssuer('Baar.ai') //issuer
    //   // .setAudience('town of bridgewater') //audience
    //   // .setExpirationTime("2h")
    //   // .encode()
    //   try {
    //     // console.log(process.env.REACT_APP_CHATBOT_SECRET_KEY)
    //     const secret = await jose.base64url.decode(
    //       process.env.REACT_APP_CHATBOT_SECRET_KEY.trim()
    //       )
    //     // console.log("secret",secret)
    //     // const encryptedJWT = await new jose.EncryptJWT({ email: userInfo.user_email })
    //     //   .setProtectedHeader({ alg: 'dir', enc: 'A128CBC-HS256' })
    //     //   .setIssuedAt()
    //     //   .setIssuer(process.env.REACT_APP_CHATBOT_ISSUER) //issuer
    //     //   .setAudience(process.env.REACT_APP_CHATBOT_AUDIENCE) //audience
    //     //   .setExpirationTime(process.env.REACT_APP_CHATBOT_EXPIRATION)
    //     //   .encrypt(secret)
    //     const encodeJWT =await new jose.SignJWT({ email: userInfo.user_email }) // details to  encode in the token
    //     .setProtectedHeader({ alg: 'HS256' }) // algorithm
    //     .setIssuedAt()
    //     .setIssuer(process.env.REACT_APP_CHATBOT_ISSUER) // issuer
    //     .setAudience(process.env.REACT_APP_CHATBOT_AUDIENCE) // audience
    //     .setExpirationTime(process.env.REACT_APP_CHATBOT_EXPIRATION) // token expiration time, e.g., "1 day"
    //     .sign(secret);

    //     await setsecuredJWT(encodeJWT)
    //   } catch (error) {
    //     console.log(error)
    //     await dispatch(showGlobalSnackbar(error.message,"error"))
    //   }
    // }  
  };

  //   if(isAuthenticated){
  //     const jwtToken = jwt.sign(
  //       {
  //         Data: userInfo.user_email
  //       }, 
  //       process.env.REACT_APP_CHATBOT_SECRET_KEY,
  //       { 
  //         expiresIn: process.env.CHATBOT_SECRET_EXPIRATION 
  //       }
  //     )
  //   }

  //   console.log("jwtToken",jwtToken)

  const onClickOutsideListener = () => {
    setopenChatSection(false)
    document.removeEventListener("click", onClickOutsideListener)
  }

  React.useEffect(()=>{

    console.log("user.chatbot_token",user?.chatbot_token)
  },[user?.chatbot_token])
  
  return (
    <>
      { isAuthenticated === false? <></>
        : openChatSection === false ?
  
        <Box
          position="fixed"
          width="165px"
          bottom={10}
          right={10}
          // zIndex={2000}
          // height="10%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack >
            {actions.map((action) => (
              <Typography
                component={Link}
                to={action.path}
                key={action.name}
                color="textPrimary"
                variant="body2"
                sx={{ textDecoration: 'none', m:'0px' ,textAlign:'right'}}
              >
                {action.name}
              </Typography>
            ))}
          </Stack>

          <SpeedDial
            ariaLabel="SpeedDial controlled open example"
            sx={{ position: 'fixed', bottom: 10, right: 10 }}
            icon={<ForumOutlinedIcon fontSize="large" />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            onClick={handleChatSectionOpen}
          >
            {actions.map((action) => (
                <SpeedDialAction
                  component={Link}
                  to={action.path}
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={handleClose}
                />
            ))}
          </SpeedDial>

        </Box>
        :
        <Box
          position="fixed"
          width={_912px ? _425px?"90%":"50%":"23%"}
          height={_912px ? "50%":"65%"}
          bottom={10}
          right={5}
          display="flex"
          alignItems="center"
          // sx={{ borderLeft: 1 }}
          justifyContent="space-between"
          onMouseLeave={() => {
            document.addEventListener("click", onClickOutsideListener)
          }}
        >
          <iframe
            key='emis-chatbot'
            title='emis-chatbot'
            src={`${process.env.REACT_APP_CHATBOT_URL}/${user?.chatbot_token?user.chatbot_token:""}`}
            width={"100%"}
            height={"100%"}
            frameBorder="0"
            // seamless="seamless" 
            // sandbox='allow-scripts allow-modal'
          // ref={iframeRef}
          // onLoad={handleIframeLoad}
          >
          </iframe>
          
        </Box>

      }
    </>
  );
}

