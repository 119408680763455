import React from "react";
import {useEffect} from 'react';
import DashboardCard from "./dashboardCard";
import { GetAdminDashboardPreviewEachKpiData} from '../../../../../actions/dashboardPreviewActions';
import { useDispatch, useSelector } from 'react-redux';


const DashboardCardContainer = React.forwardRef(
  function DashboardCardContainer({children, item, ...props }, ref) {

  const dispatch = useDispatch()


  const admindashboardPreviewKpisData = useSelector(state => state.admindashboardPreviewKpisData)
  const {error: dashboardKpisDataError, loading: dashboardKpisDataLoading, dashboardPreviewKpisData} = admindashboardPreviewKpisData


  useEffect(()=>{
    dispatch(GetAdminDashboardPreviewEachKpiData(item));
  }, [])


  return (
    <DashboardCard data={dashboardPreviewKpisData[item]} root={item} kpisdataerror={dashboardKpisDataError[item]} {...props} ref={ref}>
      {children}
    </DashboardCard>
  );
})


export default (DashboardCardContainer);

