import axios from "axios";
import { checkTokenValid } from "../../Auth/authHelper";

export const handleApiCalls = async (
  method,
  endpoint,
  permission,
  payload = {}
) => {
  let status_code = 400;
  let error_message = "";
  let response_data = "";
  let accessTokenValid = false;
  let accessToken = JSON.parse(sessionStorage.getItem("authTokens"));
  let permissionList = JSON.parse(sessionStorage.getItem("user")).permission;
  let hasPermission = permissionList.indexOf(permission) > -1;

  accessTokenValid = checkTokenValid();

  if (accessTokenValid && hasPermission) {
    try {
      // console.log("payload",payload)
      const config = {
        headers: {
          "content-type": "application/json",
          tenant: "bridgewater",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      if (method.toLowerCase() === "get") {
        const responseData = await axios.get(
          `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/${endpoint}`,
          config
        );

        status_code = responseData.status;
        response_data = responseData.data.result;
        error_message = responseData.data["error-message"]
      } else if (method.toLowerCase() === "post") {
        const responseData = await axios.post(
          `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/${endpoint}`,
          payload,
          config
        );
        console.log(responseData)
        status_code = responseData.status;
        response_data = responseData.data.result;
        error_message = responseData.data["error-message"]
      }
    } catch (error) {
      status_code = error.response.status;
      error_message = error.response.data["error-message"] || "Error!";
      throw error;
    }
  } else {
    if (!accessToken) {
      status_code = 401;
      error_message = "Session Expired! Log In Again.";
    } else if (!hasPermission) {
      status_code = 403;
      error_message = "Permission Denied. Contact Admin";
    } else {
      status_code = 500;
      error_message = "Unknown error";
    }
  }

  return [status_code, response_data, error_message];
};



export const handleApiCallsVisualizationEngine = async (
  method,
  endpoint,
  permission,
  payload = {}
) => {
  let status_code = 400;
  let error_message = "";
  let response_data = "";
  let accessTokenValid = false;
  let accessToken = JSON.parse(sessionStorage.getItem("authTokens"));
  let permissionList = JSON.parse(sessionStorage.getItem("user")).permission;
  let hasPermission = true
  if(permission) {
    hasPermission = permissionList.indexOf(permission) > -1;
  } 

  accessTokenValid = checkTokenValid();

  if (accessTokenValid && hasPermission) {
    try {
      // console.log("payload",payload)
      const config = {
        headers: {
          "content-type": "application/json",
          tenant: "bridgewater",
          Authorization: `Bearer ${accessToken}`
          // 'User-Id': 1
        },
      };

      if (method.toLowerCase() === "get") {
        const responseData = await axios.get(
          // `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/${endpoint}`,
          `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${endpoint}`,
          config
        );

        status_code = responseData.status;
        response_data = responseData.data.result;
      } else if (method.toLowerCase() === "post") {
        
        const responseData = await axios.post(
          // `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/${endpoint}`,
          `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${endpoint}`,
          payload,
          config
        );

        status_code = responseData.status;
        response_data = responseData.data.result;
      }
    } catch (error) {
      
      // if(error.message === "Network Error"){
      //   console.log(error)
      // }
      // else{
        status_code = error.message === "Network Error" ? 404 : error.response.status;
        error_message = error.message === "Network Error" ? "Network Error" :error.response.data["error"] || "Error!";
        throw error;
      // }
    }
  } else {
    if (!accessToken) {
      status_code = 401;
      error_message = "Session Expired! Log In Again.";
    } else if (!hasPermission) {
      status_code = 403;
      error_message = "Permission Denied. Contact Admin";
    } else {
      status_code = 500;
      error_message = "Unknown error";
    }
  }

  return [status_code, response_data, error_message];
};