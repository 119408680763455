import * as React from 'react';
import {Box,Button,TextField, Tooltip,Typography, Autocomplete, Skeleton, IconButton, Chip, Stack} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useNavigate, Link } from 'react-router-dom';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { shadows, useTheme } from '@mui/system';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontSize: '13px'
  },
});


export default function KpiAddDeleteModal({open, setOpen, dashboardId, setdashboardId }) {

  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    // boxShadow: 200,
    borderRadius : "20px",
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark'? '#c7c5c55c': '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [showDeleteModal, setshowDeleteModal] = React.useState(false);
  const [deleteItem, setdeleteItem] = React.useState(null);
  const [errorActive, seterrorActive] = React.useState(false);
  const [kpiList, setkpiList] = React.useState(undefined);
  const [dashboardKpiList, setdashboardKpiList] = React.useState(undefined);
  const [kpiAdd, setkpiAdd] = React.useState({});


  const handleOpen = () => {
    setOpen(true);
  };

  
  const getAllDashboardKpi = () => {
    handleApiCallsVisualizationEngine(
      "get",
      `dashboard/${dashboardId[0]}/kpis`,
      ""
      ).then((responseData) => {
          setdashboardKpiList([...responseData[1]]);
      }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
  }

  const removeKpi = (id) => {
    if (id !== ''){
      handleApiCallsVisualizationEngine(
        "post",
        `dashboard/${dashboardId[0]}/kpi/${id}/delete`,
        "",
        {"kpiId": kpiAdd}
        ).then((responseData) => {
          setdeleteItem(null)
          dispatch(showGlobalSnackbar("Kpi removed successfully.", "success"));
          getAllDashboardKpi()
        }).catch((error) => {
            // setconfirmDbAdapter(false)
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        }
      );
      }
    }
  
  const assignNewKpi = () => {
    if (Object.keys(kpiAdd).length === 0){
      seterrorActive(true)
    } else {
      seterrorActive(false)
      handleApiCallsVisualizationEngine(
          "post",
          `dashboard/kpi`,
          "",
          {
            "kpiBasicInformationId": kpiAdd.value,
            "dashboardId": dashboardId[0],
          }
          ).then((responseData) => {
            setkpiAdd({})
            dispatch(showGlobalSnackbar("Kpi assigned successfully.", "success"));
            getAllDashboardKpi()
          }).catch((error) => {
              // setconfirmDbAdapter(false)
              console.log(error);
              dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
          }
        );
      }
    }

  React.useEffect(() => {
    if(dashboardId !== null){
      handleApiCallsVisualizationEngine(
        "get",
        `dashboard/${dashboardId[0]}/kpis`,
        ""
        ).then((responseData) => {
          setdashboardKpiList([...responseData[1]]);
          
        }).catch((error) => {
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }

  }, [dashboardId]);


  React.useEffect(() => {
    if(dashboardKpiList !== undefined){
    handleApiCallsVisualizationEngine(
      "get",
      `kpis`,
      ""
      ).then((responseData) => {
        let value_list = []
        dashboardKpiList.map((item, index) =>{
          value_list.push(item.KpiBasicInformation.value)
        })
        let not_assigned = [...responseData[1]].filter((element) => !value_list.includes(element.value)); 
        // setkpiList([...responseData[1]]);
        setkpiList([...not_assigned]);
      }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
    }
  }, [dashboardKpiList]);


  return (
    open === true && dashboardId !== null?
    <Box>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '60%', height: '60%', minWidth:"200px", minHeight:'200px', display:'flex', flexDirection:'column', alignItems:'start' }}>
          <Box sx={{display:'flex', flexDirection:'row', alignItems:'start', justifyContent:"space-between", width:'100%' }}>
            <Box width={'70%'}>
              <CustomWidthTooltip title={`${dashboardId[1]}`} arrow> 
                <Typography noWrap sx={{fontSize:"23px"}}  fontWeight={500} pb={3}>{`Add/Remove Kpi From Dashboard - ${dashboardId[1]}`}</Typography>
              </CustomWidthTooltip>
            </Box>
            <Box>
              <IconButton
                sx={{background:'#FF7A7A6b', '&:hover': {background:'#FF5959'}}}
                size='small'
                onClick={() => {
                  setdashboardId(null);
                  setkpiList([])
                  setdashboardKpiList(undefined)
                  setkpiAdd({})
                  setOpen(false)
                }}
              >
                <ClearOutlinedIcon/>
              </IconButton>
            </Box>  
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            {kpiList !== undefined ? 
              <Box sx={{width:'90%', height:'100%'}}>
                <Autocomplete
                  disableClearable 
                  fullWidth id="controllable-states-demo" 
                  variant="outlined" 
                  value={Object.keys(kpiAdd).length > 0 ? kpiAdd : null}
                  options={kpiList}
                  // error={errorActive ? kpiAdd === '': false}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={(option) => option.kpiName}
                  onChange={(event, newValue) => {setkpiAdd(newValue)} }
                  renderOption={(props, option) => <li {...props}>{option.kpiName}</li>}
                  renderInput={(params) => <TextField {...params} label="Assign Kpi" error={errorActive ? Object.keys(kpiAdd).length === 0: false}/>}
                /> 
              </Box>
              : <Skeleton variant="rectangular" height={'52px'} width={'90%'}></Skeleton>
            }
            <Box>
              <Button
                variant="contained"
                // size='small'
                onClick={assignNewKpi}
              >
                Add Kpi
              </Button>
            </Box>
          </Box>
          {showDeleteModal === true && deleteItem !== null ?
          <DeleteButtonWithConfirmationPopUp mainData={dashboardKpiList} deleteitem={deleteItem} deleteFunc={removeKpi} needButton={false}> </DeleteButtonWithConfirmationPopUp>
          : <></>}
          <Box height={'250px'} width='100%' mt={2} borderRadius={'10px'} bgcolor='#6f655521' p={2} sx={{overflow: 'auto'}}>
            {dashboardKpiList === undefined ?
              <Skeleton variant="rectangular" height={'100%'}></Skeleton> 
              :
              dashboardKpiList.length > 0 ?
              <Stack direction="row" spacing={1} useFlexGap  flexWrap="wrap">
                {dashboardKpiList.map((item, index) => (
                  <Chip 
                    key={index} 
                    label={`${item.KpiBasicInformation.kpiName}`} 
                    sx={{background: `${theme.palette.mode === 'dark'? '#000': '#fff'}`, boxShadow:2,
                    color: `${theme.palette.mode === 'dark'? '#fff': '#000'}`,
                    fontSize:'15px', fontWeight:'500', minWidth:'70px',
                    display:'flex', alignItems:'center', justifyContent:'space-between'
                    }}
                    
                    onDelete={() => {
                      setshowDeleteModal(true)
                      setdeleteItem(item.KpiBasicInformation.value)
                      // removeKpi(item.KpiBasicInformation.value)
                    }} 
                  />
                ))}
              </Stack> 
              : 
              <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}} height={'100%'}>
                <Typography sx={{fontSize:"15px"}} fontWeight={700}>No Kpi assigned to this dashboard.</Typography>
              </Box>
            }
          </Box>
        </Box>
      </Modal>
    </Box>
    :<></>
  );
}
